import { AlertBuilder, AlertType } from "@app/alert";
import { getElementById } from "@app/dom";
import { EditResourceModalData } from "@app/main/edit-resource";
import { Playlists, State } from "@app/main/state";
import {
	FfmpegResource,
	ListKind,
	ListShort,
	makeErrorText,
	PlayResourceSource,
	queueTranslateError,
	requests,
	ResourcePlayResource,
	SpotifyResource,
	SubResource,
	UnregisteredResource,
	UrlFfmpegResource,
	UrlResourceSource,
} from "@app/requests";
import { always, filterMap, secondsFromMicroSeconds, secondsToDuration } from "@app/utility";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ComponentChildren, h } from "tsx-dom";

export interface ListResourceSourceProperties {
	name: string;
	resourceId: string;
	priority: SubResource | null;
}

function sortPlaylistsPriority(lists: ListShort[]) {
	function kindToIndex(kind: ListKind) {
		let value;
		if (kind === "normal") {
			value = 1;
		} else {
			value = 0;
		}
		return value;
	}

	lists.sort((a, b) => {
		const kindA = kindToIndex(a.kind);
		const kindB = kindToIndex(b.kind);
		const diff = kindA - kindB;
		if (diff !== 0) {
			return diff;
		}
		return a.name.localeCompare(b.name);
	});
}

export function makeAlertBubble(type: AlertType, message: string, duration = 3000): HTMLDivElement {
	return new AlertBuilder(type).text(message).dismissible().hideAfter(duration).build();
}

export function alertBubble(type: AlertType, message: string, duration = 3000): void {
	showAlert(makeAlertBubble(type, message, duration));
}

export function showAlert(alert: HTMLDivElement): void {
	getElementById("alerts").appendChild(alert);
}

export function spinningAlert<T>(
	xhr: Promise<T>,
	text: string,
	translateError: { [key: string]: string },
	done: (value: T) => string,
	failure?: () => string,
) {
	const alertElement = new AlertBuilder("primary").spinner().text(text).build();
	showAlert(alertElement);
	return xhr.then(
		value => {
			alertElement.replaceWith(makeAlertBubble("success", done(value)));
		},
		xhr => {
			return makeAlertBubbleFromXhrError(xhr, translateError, failure === undefined ? undefined : failure()).then(
				e => alertElement.replaceWith(e),
			);
		},
	);
}

export async function makeAlertBubbleFromXhrError(
	xhr: Response,
	translateError: { [key: string]: string },
	message = "Error occurred",
): Promise<HTMLDivElement> {
	const text = await makeErrorText(xhr, translateError);
	return makeAlertBubble("danger", `${message}: ${text}`);
}

export async function alertBubbleFromXhrError(
	xhr: Response,
	translateError: { [key: string]: string },
	message = "Error occurred",
): Promise<void> {
	showAlert(await makeAlertBubbleFromXhrError(xhr, translateError, message));
}

export const LOGIN_PAGE = "login.html";

export function wrapRequestFailure(translateError: { [key: string]: string } = {}, message = "Error occurred") {
	return (xhr: Response): Promise<never> => {
		if (xhr.status === 401) {
			alertBubble(
				"danger",
				"You are not authorized to visit this page or use this function, redirecting to login page...",
			);
			setTimeout(() => window.location.assign(LOGIN_PAGE), 2000);
		} else {
			alertBubbleFromXhrError(xhr, translateError, message).then();
		}

		return Promise.reject(xhr);
	};
}

export function wrapRequest<T>(
	request: Promise<T>,
	translateError: { [key: string]: string } = {},
	message = "Error occurred",
): Promise<T> {
	return request.then(v => v, wrapRequestFailure(translateError, message));
}

function queueSong(button: HTMLElement, front: boolean, source: PlayResourceSource): Promise<void> {
	if (button.hasAttribute("queueing")) {
		return Promise.reject();
	}
	button.setAttribute("queueing", "");

	let request;
	if (front) {
		request = requests.player.front(source);
	} else {
		request = requests.player.enqueue(source);
	}

	return always(wrapRequest(request, queueTranslateError), () => button.removeAttribute("queueing"));
}

export interface QueueSource {
	source: PlayResourceSource;
	description: string | PromiseLike<string>;
}

export function onQueueButtonClick(front: boolean, sources: QueueSource[]): Promise<void> {
	const promises = sources.map(source => {
		let request: Promise<void>;
		if (front) {
			request = requests.player.front(source.source);
		} else {
			request = requests.player.enqueue(source.source);
		}

		return Promise.resolve(source.description).then(description => {
			const frontString = front ? "Fronting" : "Queueing";
			const message = `${frontString} ${description}`;
			spinningAlert(request, message, queueTranslateError, () => {
				const frontString = front ? "Fronted" : "Queued";
				return `${frontString} ${description}`;
			});
		});
	});
	return Promise.allSettled(promises).then(() => {
		return;
	});
}

export class SingleTimeout {
	private handle?: number;

	hasTimeout(): boolean {
		return this.handle !== undefined;
	}

	clear(): void {
		if (this.handle !== undefined) {
			clearTimeout(this.handle);
			this.handle = undefined;
		}
	}

	timeout(f: FrameRequestCallback, ms?: number): void {
		this.clear();
		this.handle = setTimeout(time => {
			this.handle = undefined;
			f(time);
		}, ms);
	}
}

export function createFasIcon(icon: string): HTMLElement {
	const i = document.createElement("i");
	i.classList.add("fas", icon);
	return i;
}

export type Recency = "high" | "medium" | "low" | "none";

export class RecencyCalc {
	// 15 minutes
	public static HIGH_RECENCY_MS = 15 * 60 * 1000;
	// 2 hours
	public static MEDIUM_RECENCY_MS = 8 * RecencyCalc.HIGH_RECENCY_MS;
	// 8 hours
	public static LOW_RECENCY_MS = 4 * RecencyCalc.MEDIUM_RECENCY_MS;

	private readonly time: number;
	private readonly state: State;

	constructor(state: State) {
		this.time = Date.now();
		this.state = state;
	}

	get(resourceId: string): Recency {
		const playerSong = this.state.player.song;
		if (playerSong !== null && playerSong.resource.resourceId === resourceId) {
			return "high";
		}
		const queueItem = this.state.queue.resourceIdToItem.get(resourceId);
		if (queueItem !== undefined) {
			return "high";
		}
		const item = this.state.recentlyPlayed.resourceIdToMostRecent.get(resourceId);
		if (item === undefined) {
			return "none";
		}
		const delta = Math.max(0, this.time - Date.parse(item.startTime));
		if (delta <= RecencyCalc.HIGH_RECENCY_MS) {
			return "high";
		} else if (delta <= RecencyCalc.MEDIUM_RECENCY_MS) {
			return "medium";
		} else if (delta <= RecencyCalc.LOW_RECENCY_MS) {
			return "low";
		} else {
			return "none";
		}
	}
}

const recencyToClass = {
	high: "recency-high",
	medium: "recency-medium",
	low: "recency-low",
	none: "recency-none",
};

export function createQueueButton(recency: Recency, makeSource: () => QueueSource | undefined): HTMLButtonElement {
	const button = document.createElement("button");
	button.appendChild(createFasIcon("fa-plus"));

	const timeout = new SingleTimeout();
	const reset = () => {
		button.firstChild?.replaceWith(createFasIcon("fa-plus"));
	};
	const queueFinished = (front: boolean) => {
		if (front) {
			button.firstChild?.replaceWith(createFasIcon("fa-angle-up"));
		} else {
			button.firstChild?.replaceWith(createFasIcon("fa-check"));
		}
		timeout.timeout(reset, 2500);
	};

	const recencyCls = recencyToClass[recency];
	button.classList.add("btn", "btn-secondary", "btn-line-height", "btn-width", "queue-button", recencyCls);
	button.addEventListener("click", e => {
		button.blur();

		const source = makeSource();
		if (source === undefined) {
			return;
		}
		const front = e.shiftKey;
		always(
			queueSong(button, front, source.source).then(() => {
				const cl = button.classList;
				cl.add("recency-high");
				cl.remove(recencyCls);
			}),
			() => queueFinished(front),
		);
	});
	return button;
}

export function Link({ url, children }: { url: string; children?: ComponentChildren }): JSX.Element {
	return (
		<a href={url} target="_blank" class="no-link-format underline-on-hover">
			{children}
		</a>
	);
}

export function extendLink(a: HTMLAnchorElement, url: string): HTMLAnchorElement {
	a.setAttribute("href", url);
	a.setAttribute("target", "_blank");
	a.classList.add("no-link-format", "underline-on-hover");
	return a;
}

export function createTextLink(text: string, url: string): JSX.Element {
	return <Link url={url}>{text}</Link>;
}

export function updateUrlAfterResourceChange(a: HTMLElement, resource: EditResourceModalData): void {
	let url;
	if ((resource.priority === "ffmpeg" || resource.spotify === null) && resource.ffmpeg != null) {
		url = resource.ffmpeg.url;
	} else {
		url = resource.spotify as string;
	}
	a.setAttribute("href", url);
}

export function urlFromFfmpegResource(resource: FfmpegResource): string {
	let url;
	if ("youtube" in resource) {
		url = resource.youtube;
		if (resource.startTimestamp) {
			url += `?t=${secondsFromMicroSeconds(resource.startTimestamp)}`;
		}
	} else {
		url = (resource as UrlFfmpegResource).url;
		if (resource.startTimestamp && url.startsWith("https://soundcloud.com")) {
			url += `#t=${secondsToDuration(secondsFromMicroSeconds(resource.startTimestamp))}`;
		}
	}
	return url;
}

export function urlFromSpotifyResource(resource: SpotifyResource): string {
	return resource.trackId;
}

export function urlFromResource(
	resource: { ffmpeg: FfmpegResource | null; spotify: SpotifyResource | null },
	priority: SubResource | null,
): string {
	let url;
	if (resource.ffmpeg !== null && (priority === "ffmpeg" || resource.spotify === null)) {
		url = urlFromFfmpegResource(resource.ffmpeg);
	} else {
		url = urlFromSpotifyResource(resource.spotify as SpotifyResource);
	}
	return url;
}

export function urlFromUnregisteredResource(resource: UnregisteredResource): string {
	return urlFromResource(resource, resource.priority);
}

export function createAlsoInHoverText(containingLists: ListShort[]): string {
	let hoverText = "";
	if (containingLists.length > 0) {
		hoverText = `Also in:<br><ul>`;
		containingLists.forEach(playlist => {
			hoverText += `<li>${playlist.name}</li>`;
		});
		hoverText += "</ul>";
	}
	return hoverText;
}

export function toPrioritySortedLists(playlists: string[], lookup: Playlists): ListShort[] {
	const containingLists = filterMap(playlists, id => lookup.byId(id));
	sortPlaylistsPriority(containingLists);
	return containingLists;
}

export function makeListResourceSource(
	{ name, priority, resourceId }: ListResourceSourceProperties,
	list: ListShort,
	sub?: SubResource,
): QueueSource {
	if (sub === undefined && priority !== null) {
		sub = priority;
	}
	const source = new ResourcePlayResource(resourceId, list.id, sub);
	const description = `'${name}' from playlist '${list.name}'`;
	return {
		source,
		description,
	};
}

export function makeUrlResourceSource(name: string, url: string): QueueSource {
	const source = new UrlResourceSource(url);
	const description = `'${name}'`;
	return {
		source,
		description,
	};
}
